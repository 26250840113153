import "../App.css";
import { useEffect, useState } from "react";

import Discord from "../icons/Discord";
import Roblox from "../icons/Roblox";
import Instagram from "../icons/Instagram";
import Spotify from "../icons/Spotify";
import Steam from "../icons/Steam";
import Tiktok from "../icons/Tiktok";
import SoundCloud from "../icons/SoundCloud";

import Visualizer from "../components/Visualizer";
import background from "../images/zak-bg.png"
import pfp from "../images/zak-pfp.jpg"

function Zak() {
    const [title, setTitle] = useState("zk");
    const [description, setDescription] = useState(
        "a broken name, a ghost to see"
    );
    const [isEntered, setIsEntered] = useState(false);

    if (!isEntered) {
        return (
            <div class="">
                <div
                    className="fixed inset-0 flex items-center justify-center bg-[#0153A9] cursor-pointer"
                    onClick={() => setIsEntered(true)} // Click to enter
                >
                    <h1 class="text-white filter animate-pulse drop-shadow-[0_0_3.5px_rgb(255,255,255)] text-xl sm:text-xl md:text-xl lg:text-5xl">
                        &lt; click to enter &gt;
                    </h1>
                </div>
            </div>
        );
    }

    return (
        <div class="">
            <img
                class="fixed inset-0 w-full h-full object-cover filter blur-[5px] scale-110"
                src={background}
            ></img>
            <div class="flex flex-col relative z-0 justify-center items-center h-screen bg-mantle custom-cursor">
                <div className="absolute inset-0 flex justify-center items-center">
                    <Visualizer user="zak" />
                </div>

                <div class="flex flex-col justify-center items-center h-screen w-[42rem] max-w-[90%] max-h-[95%]">
                    <div class="bg-[#151345]/50 min-w-full min-h-[26rem] mb-4 overflow-hidden shadow-md shadow-[#040622] backdrop-blur-md">
                        <div class="h-0.5 w-auto bg-[linear-gradient(100deg,_#1a1c44,_#2a235c,_#2a276d,_#89b4fa,_#343a8c,_#3f4aa3)] animate-gradient"></div>

                        <div class="w-full h-[14rem] flex justify-center items-center">
                            <img
                                src={pfp}
                                class="filter shadow-md shadow-[#040622] w-20 h-20 sm:w-24 sm:h-24 md:w-32 md:h-32 rounded-full"
                                alt="avatar"
                            ></img>
                        </div>

                        <div class="w-full h-[12rem] flex flex-col items-center text-white font-inter">
                            <h1 class="filter drop-shadow-[0_0_3.5px_rgb(255,255,255)] font-bold text-2xl sm:text-3xl md:text-4xl lg:text-5xl">
                                {title}
                            </h1>
                            <p class="text-text filter drop-shadow-[0_0_3.5px_rgb(205,214,244)] text-sm sm:text-md md:text-lg mt-2">
                                {description}
                            </p>

                            <div class="0 h-[5rem] w-full mt-auto flex flex-row justify-center items-center gap-5">
                                <a
                                    class="filter drop-shadow-[0_0_3.5px_rgb(51,110,212)] w-[5%] aspect-square"
                                    href="discord://-/users/1027667256076943424"
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    <Discord fill="#336ed4" />
                                </a>
                                <a
                                    class="filter drop-shadow-[0_0_3.5px_rgb(51,110,212)] w-[5%] aspect-square"
                                    href="https://www.tiktok.com/@MS4wLjABAAAAJYSt2wZBNLeR3G5YDNqew3nEd5uiFNaOM4S5WxsIy5uQLuAh8_qaJ9YIxJHtU8HG"
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    <Tiktok fill="#336ed4" />
                                </a>
                                <a
                                    class="filter drop-shadow-[0_0_3.5px_rgb(51,110,212)] w-[5%] aspect-square"
                                    href="https://instagram.com/zasphr"
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    <Instagram fill="#336ed4" />
                                </a>
                                <a
                                    class="filter drop-shadow-[0_0_3.5px_rgb(51,110,212)] w-[5%] aspect-square"
                                    href="https://soundcloud.com/shrineofmalice"
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    <SoundCloud fill="#336ed4" />
                                </a>
                                <a
                                    class="filter drop-shadow-[0_0_3.5px_rgb(51,110,212)] w-[5%] aspect-square"
                                    href="https://www.roblox.com/users/4133369821/profile"
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    <Roblox fill="#336ed4" />
                                </a>
                                <a
                                    class="filter drop-shadow-[0_0_3.5px_rgb(51,110,212)] w-[5%] aspect-square"
                                    href="https://steamcommunity.com/id/zakrificed"
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    <Steam fill="#336ed4" />
                                </a>
                                <a
                                    class="filter drop-shadow-[0_0_3.5px_rgb(51,110,212)] w-[5%] aspect-square"
                                    href="https://open.spotify.com/user/iwyi7194jz5vsscks6bofgvj0?si=c38b897d8e4547b1"
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    <Spotify fill="#336ed4" />
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Zak;
