import "../App.css";
import { useState } from "react";

import Discord from "../icons/Discord";
import Roblox from "../icons/Roblox";
import Instagram from "../icons/Instagram";
import Spotify from "../icons/Spotify";
import Steam from "../icons/Steam";
import Osu from "../icons/Osu"
import Visualizer from "../components/Visualizer";
import pfp from "../images/tyr-pfp.jpg"

function Tyr() {
    const [title] = useState("tyr");
    const [description] = useState(
        "if you’re hungry why don’t you go and eat shit"
    );
    const [isEntered, setIsEntered] = useState(false);

    if (!isEntered) {
        return (
            <div class="">
                <div
                    className="fixed inset-0 flex items-center justify-center bg-[#121212] cursor-pointer"
                    onClick={() => setIsEntered(true)}
                >
                    <h1 class="text-[#32cd32] filter animate-pulse drop-shadow-[0_0_3.5px_rgb(50,205,50)] text-xl sm:text-xl md:text-xl lg:text-5xl">
                        &lt; click to enter &gt;
                    </h1>
                </div>
            </div>
        );
    }

    return (
        <div class="">
            <div class="flex flex-col relative z-0 justify-center items-center h-screen bg-[#121212]">
                <div className="absolute inset-0 flex justify-center items-center">
                    <Visualizer user="tyr" />
                </div>

                <div class="flex flex-col justify-center items-center h-screen w-[42rem] max-w-[90%] max-h-[95%]">
                    <div class="bg-[#2e2d2d]/70 min-w-full min-h-[26rem] mb-4 overflow-hidden shadow-md shadow-[#0d0d0d] backdrop-blur-md">
                        <div class="h-0.5 w-auto bg-[linear-gradient(100deg,_#228b22,_#32cd32,_#7cfc00,_#a2ff59,_#ccff99,_#a2ff59,_#7cfc00,_#32cd32)] animate-gradient"></div>

                        <div class="w-full h-[14rem] flex justify-center items-center">
                            <img
                                src={pfp}
                                class="filter shadow-md shadow-[#0d0d0d] w-20 h-20 sm:w-24 sm:h-24 md:w-32 md:h-32 rounded-full"
                                alt="avatar"
                            ></img>
                        </div>

                        <div class="w-full h-[12rem] flex flex-col items-center text-white font-inter">
                            <h1 class="filter drop-shadow-[0_0_3.5px_rgb(255,255,255)] font-bold text-2xl sm:text-3xl md:text-4xl lg:text-5xl">
                                {title}
                            </h1>
                            <p class="text-text filter drop-shadow-[0_0_3.5px_rgb(255,255,255)] text-sm sm:text-md md:text-lg mt-2">
                                {description}
                            </p>

                            <div class="0 h-[5rem] w-full mt-auto flex flex-row justify-center items-center gap-5">
                                <a
                                    class="filter drop-shadow-[0_0_3.5px_rgb(255,255,255)] w-[5%] aspect-square"
                                    href="https://osu.ppy.sh/users/36073189"
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    <Osu fill="#e6e6e6" />
                                </a>

                                <a
                                    class="filter drop-shadow-[0_0_3.5px_rgb(255,255,255)] w-[5%] aspect-square"
                                    href="https://steamcommunity.com/profiles/76561198999946316/"
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    <Steam fill="#e6e6e6" />
                                </a>

                                <a
                                    class="filter drop-shadow-[0_0_3.5px_rgb(255,255,255)] w-[5%] aspect-square"
                                    href="https://www.roblox.com/users/2201747046/profile"
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    <Roblox fill="#e6e6e6" />
                                </a>

                                <a
                                    class="filter drop-shadow-[0_0_3.5px_rgb(255,255,255)] w-[5%] aspect-square"
                                    href="https://www.instagram.com/manipulationofweaksauls?igsh=MTJjbjRncXdtbWN4cQ%3D%3D&utm_source=qr "
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    <Instagram fill="#e6e6e6" />
                                </a>

                                <a
                                    class="filter drop-shadow-[0_0_3.5px_rgb(255,255,255)] w-[5%] aspect-square"
                                    href="https://open.spotify.com/user/8orrsjexbpx4y4dj5bkl86c5v?si=C9Q3ckjdRY-Ff1ZeY5b_wA"
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    <Spotify fill="#e6e6e6" />
                                </a>

                                <a
                                    class="filter drop-shadow-[0_0_3.5px_rgb(255,255,255)] w-[5%] aspect-square"
                                    href="discord://-/users/926636302269554718"
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    <Discord fill="#e6e6e6" />
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Tyr;
