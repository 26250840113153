import "../App.css";
import { useEffect, useState } from "react";

import Discord from "../icons/Discord";
import Roblox from "../icons/Roblox";
import Instagram from "../icons/Instagram";
import Spotify from "../icons/Spotify";
import Tiktok from "../icons/Tiktok";
import SoundCloud from "../icons/SoundCloud";
import Visualizer from "../components/Visualizer";
import background from "../images/background.jpg";

function Gabe() {
    const [avatarUrl, setAvatarUrl] = useState("");
    const [title, setTitle] = useState("gabe");
    const [description, setDescription] = useState(
        "the quickest way to the center of the heart"
    );
    const [isEntered, setIsEntered] = useState(false);

    useEffect(() => {
        const getAvatar = async () => {
            const res = await fetch(
                "https://ib2.dev/fetch.php?user=gabe&type=avatar"
            );
            const url = await res.json();
            return url;
        };

        getAvatar().then((url) => {
            if (url) {
                setAvatarUrl(url);
            }
        });
    }, []);

    if (!isEntered) {
        return (
            <div class="">
                <div
                    className="fixed inset-0 flex items-center justify-center bg-black cursor-pointer"
                    onClick={() => setIsEntered(true)} // Click to enter
                >
                    <h1 class="text-[#D6D4D6ff] filter animate-pulse drop-shadow-[0_0_3.5px_rgb(255,255,255)] text-xl sm:text-xl md:text-xl lg:text-5xl">
                        &lt; click to enter &gt;
                    </h1>
                </div>
            </div>
        );
    }

    return (
        <div>
            
            <img
                class="fixed inset-0 w-full h-full object-cover brightness-[15%]"
                src={background}
            ></img>


            <div class="flex flex-col relative z-0 justify-center items-center h-screen bg-mantle">
            <div className="absolute inset-0 flex justify-center items-center">
                        <Visualizer user="gabe" />
                    </div>
                <div class="flex flex-col justify-center items-center h-screen w-[42rem] max-w-[90%] max-h-[95%]">

                    <div class="bg-[#141414]/25 border-[1px] border-[#202020] rounded-xl min-w-full min-h-[26rem] mb-4 overflow-hidden backdrop-blur-md">
                        {/* <div class="h-0.5 w-auto bg-[linear-gradient(100deg,_#010001ff,_#030103ff,_#232123ff,_#060506ff,_#484648ff,_#0C0A0Cff,_#D6D4D6ff)] animate-gradient"></div> */}

                        <div class="w-full h-[14rem] flex justify-center items-center">
                            <img
                                src={avatarUrl}
                                class="filter shadow-md shadow-black w-20 h-20 sm:w-24 sm:h-24 md:w-32 md:h-32 rounded-full"
                                alt="avatar"
                            ></img>
                        </div>

                        <div class="w-full h-[12rem] flex flex-col items-center text-[#D6D4D6ff] font-inter">
                            <h1 class="filter drop-shadow-[0_0_3.5px_rgb(255,255,255)] font-bold text-2xl sm:text-3xl md:text-4xl lg:text-5xl">
                                {title}
                            </h1>
                            <p class="text-[#a8a8a8] filter drop-shadow-[0_0_3.5px_rgb(255,255,255)] text-sm sm:text-md md:text-lg mt-2">
                                {description}
                            </p>

                            <div class="0 h-[5rem] w-full mt-auto flex flex-row justify-center items-center gap-5">
                                <a
                                    class="filter drop-shadow-[0_0_3.5px_rgb(205,214,244)] w-[5%] aspect-square"
                                    href="discord://-/users/1266041307906838529"
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    <Discord fill="#ffffff" />
                                </a>
                                <a
                                    class="filter drop-shadow-[0_0_3.5px_rgb(205,214,244)] w-[5%] aspect-square"
                                    href="https://www.roblox.com/users/6009396676/profile"
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    <Roblox fill="#ffffff" />
                                </a>
                                <a
                                    class="filter drop-shadow-[0_0_3.5px_rgb(205,214,244)] w-[5%] aspect-square"
                                    href="https://www.instagram.com/fereydawn/"
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    <Instagram fill="#ffffff" />
                                </a>
                                <a
                                    class="filter drop-shadow-[0_0_3.5px_rgb(205,214,244)] w-[5%] aspect-square"
                                    href="https://open.spotify.com/user/kfa3yi7wdi4vaokj0z6i7feu5"
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    <Spotify fill="#ffffff" />
                                </a>
                                <a
                                    class="filter drop-shadow-[0_0_3.5px_rgb(205,214,244)] w-[5%] aspect-square"
                                    href="https://www.tiktok.com/@MS4wLjABAAAAPi62ylS_t7Ud27AFu8v7f4_mq8K9Sgfc5edRGvvoZVBAS5kDjzV8SKS0HPKOE8NF"
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    <Tiktok fill="#ffffff" />
                                </a>
                                <a
                                    class="filter drop-shadow-[0_0_3.5px_rgb(205,214,244)] w-[5%] aspect-square"
                                    href="https://www.tiktok.com/@MS4wLjABAAAAPi62ylS_t7Ud27AFu8v7f4_mq8K9Sgfc5edRGvvoZVBAS5kDjzV8SKS0HPKOE8NF"
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    <SoundCloud fill="#ffffff" />
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Gabe;
