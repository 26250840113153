import { useEffect, useRef } from "react";
import song from "../audio/song.mp3";
import song2 from "../audio/song2.mp3";
import song3 from "../audio/song3.mp3";
import song4 from "../audio/song4.mp3";
import song5 from "../audio/song5.mp3"
import song6 from "../audio/song6.mp3"

const AudioVisualizer = ({ user }) => {
    const audioRef = useRef(null);
    const canvasRef = useRef(null);
    const contextRef = useRef(null);
    const sourceRef = useRef(null);
    const analyserRef = useRef(null);

    useEffect(() => {
        const setupAudio = () => {
            try {
                let audioFile = "";
                let color = "";
                
                switch (user) {
                    case "gabe":
                        audioFile = song2;
                        color = "rgb(100, 100, 100)";
                        break;
                    case "nisa":
                        audioFile = song3;
                        color = "rgb(234, 118, 203)"
                        break;
                    case "zak":
                        audioFile = song4;
                        color = "rgb(255, 255, 255)"
                        break;
                    case "tyr":
                        audioFile = song5;
                        color = "rgb(50, 205, 50)"
                        break;
                    case "jc":
                        audioFile = song6;
                        // color = "rgb(124, 149, 217)"
                        color = "rgb(165, 168, 176)"
                        break;
                    default:
                        audioFile = song;
                        color = "rgb(245, 194, 231)";
                        break;
                }
                
                const audio = new Audio(audioFile);
                audioRef.current = audio;

                audio.oncanplaythrough = () => {
                    audio.play();
                    audio.volume = 0.15;
                    
                    const context = new (window.AudioContext || window.webkitAudioContext)();
                    contextRef.current = context;

                    if (sourceRef.current) {
                        sourceRef.current.disconnect();
                    }

                    const src = context.createMediaElementSource(audio);
                    sourceRef.current = src;

                    const analyser = context.createAnalyser();
                    analyserRef.current = analyser;

                    const canvas = canvasRef.current;
                    canvas.width = window.innerWidth;
                    canvas.height = window.innerHeight / 2;
                    const ctx = canvas.getContext("2d");
                    src.connect(analyser);
                    analyser.connect(context.destination);
                    analyser.fftSize = 256;
                    const bufferLength = analyser.frequencyBinCount;
                    const dataArray = new Uint8Array(bufferLength);
                    const smoothedDataArray = new Uint8Array(bufferLength);
                    const WIDTH = canvas.width;
                    const HEIGHT = canvas.height;
                    const smoothing = 0.75;

                    const renderFrame = () => {
                        requestAnimationFrame(renderFrame);
                        analyser.getByteFrequencyData(dataArray);
                        for (let i = 0; i < bufferLength; i++) {
                            smoothedDataArray[i] = smoothing * smoothedDataArray[i] + (1 - smoothing) * dataArray[i];
                        }
                        ctx.clearRect(0, 0, WIDTH, HEIGHT);
                    
                        ctx.shadowBlur = 10;
                        ctx.shadowColor = color;
                        ctx.shadowOffsetY = 0;
                    
                        const barWidth = (WIDTH / bufferLength) * 0.5;
                        const barSpacing = barWidth * 0.5;
                        const maxBarHeight = HEIGHT / 4;
                        ctx.fillStyle = color;
                    
                        for (let i = 0; i < bufferLength; i++) {
                            const bassFactor = i < 10 ? 0.75 + (i / 10) * 0.4 : 1;
                            const barHeight = (smoothedDataArray[i] / 255) * maxBarHeight * bassFactor * 1.5;
                            const xLeft = (WIDTH / 2) - ((i + 1) * (barWidth + barSpacing));
                            const xRight = (WIDTH / 2) + (i * (barWidth + barSpacing));
                    
                            ctx.fillRect(xLeft, (HEIGHT / 2) - barHeight, barWidth, barHeight * 2);
                            ctx.fillRect(xRight, (HEIGHT / 2) - barHeight, barWidth, barHeight * 2);
                        }
                    
                        ctx.shadowBlur = 0;
                        ctx.shadowColor = "transparent";
                    };

                    renderFrame();
                };

            } catch (err) {
                console.error("Setup error:", err);
            }
        };

        setupAudio();

        const handleResize = () => {
            if (canvasRef.current) {
                canvasRef.current.width = window.innerWidth;
                canvasRef.current.height = window.innerHeight / 2;
            }
        };

        window.addEventListener("resize", handleResize);

        return () => {
            window.removeEventListener("resize", handleResize);
            if (audioRef.current) {
                audioRef.current.pause();
                audioRef.current.src = ""; // Clear the src to stop loading
                audioRef.current.remove(); // Remove the <audio> element from the DOM
            }
            if (sourceRef.current) {
                sourceRef.current.disconnect();
            }
            if (analyserRef.current) {
                analyserRef.current.disconnect();
            }
            if (contextRef.current) {
                contextRef.current.close();
            }
        };
    }, [user]);

    return (
        <div>
            <canvas ref={canvasRef} className="w-full h-1/2" />
        </div>
    );
};

export default AudioVisualizer;