import "../App.css";
import { useEffect, useState } from "react";

import Discord from "../icons/Discord";
import Roblox from "../icons/Roblox";
import Github from "../icons/Github";
import Instagram from "../icons/Instagram";
import Spotify from "../icons/Spotify";
import Steam from "../icons/Steam";
import Visualizer from "../components/Visualizer";
import pfp from "../images/nisa-pfp.jpg";

import background from "../images/hellokitty.jpeg";
import Tiktok from "../icons/Tiktok";

function Nisa() {
    const [title, setTitle] = useState("nisa <3");
    const [description, setDescription] = useState(
        "i want to make a little flower field bloom in my heart"
    );
    const [isEntered, setIsEntered] = useState(false);

    useEffect(() => {
        if (isEntered) {
            const svgElements = document.querySelectorAll("svg");
            svgElements.forEach((svg) => {
                svg.setAttribute("fill", "#F8CBDF");
            });
        }
    }, [isEntered]);

    if (!isEntered) {
        return (
            <div class="latte">
                <div
                    className="fixed inset-0 flex items-center justify-center bg-[#F8CBDF] cursor-pointer"
                    onClick={() => setIsEntered(true)} // Click to enter
                >
                    <h1 class="text-pink filter animate-pulse drop-shadow-[0_0_3.5px_rgb(235,124,206)] text-xl sm:text-xl md:text-xl lg:text-5xl">
                        &lt; click to enter &gt;
                    </h1>
                </div>
            </div>
        );
    }

    return (
        <div class="latte">
            <div class="flex flex-col relative z-0 justify-center items-center h-screen bg-mantle custom-cursor">
                <img
                    class="fixed inset-0 w-full h-full object-cover filter blur-[5px]"
                    src={background}
                ></img>
                <div className="absolute inset-0 flex justify-center items-center">
                    <Visualizer user="nisa" />
                </div>

                <div class="flex flex-col justify-center items-center h-screen w-[42rem] max-w-[90%] max-h-[95%]">
                    <div class="bg-base/70 min-w-full min-h-[26rem] mb-4 overflow-hidden shadow-md shadow-crust backdrop-blur-md">
                        <div class="h-0.5 w-auto bg-[linear-gradient(100deg,_#ea76cb,_#f5c2e7,_#f8b3d6,_#f5a6c9,_#f598bc,_#f48fb5)] animate-gradient"></div>

                        <div class="w-full h-[14rem] flex justify-center items-center">
                            <img
                                src={pfp}
                                class="filter shadow-md shadow-[#4c4f69] w-20 h-20 sm:w-24 sm:h-24 md:w-32 md:h-32 rounded-full"
                                alt="avatar"
                            ></img>
                        </div>

                        <div class="w-full h-[12rem] flex flex-col items-center text-pink font-inter">
                            <h1 class="filter drop-shadow-[0_0_3.5px_rgb(235,124,206)] font-bold text-2xl sm:text-3xl md:text-4xl lg:text-5xl">
                                {title}
                            </h1>
                            <p class="text-[#f59ac2] filter drop-shadow-[0_0_3.5px_rgb(245,154,194)] text-sm sm:text-md md:text-lg mt-2">
                                {description}
                            </p>

                            <div class="0 h-[5rem] w-full mt-auto flex flex-row justify-center items-center gap-5">
                                <a
                                    class="filter drop-shadow-[0_0_3.5px_rgb(205,214,244)] w-[5%] aspect-square"
                                    href="discord://-/users/1060776142837121127"
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    <Discord fill="#f59ac2" />
                                </a>
                                <a
                                    class="filter drop-shadow-[0_0_3.5px_rgb(245,154,194)] w-[5%] aspect-square"
                                    href="https://open.spotify.com/user/31yxyhojfxdufwz5kka3pmgzxi4m?si=0e4588ff39c04417"
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    <Spotify fill="#f59ac2" />
                                </a>
                                <a
                                    class="filter drop-shadow-[0_0_3.5px_rgb(245,154,194)] w-[5%] aspect-square"
                                    href="https://www.tiktok.com/@n1sa.85"
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    <Tiktok fill="#f59ac2" />
                                </a>
                                <a
                                    class="filter drop-shadow-[0_0_3.5px_rgb(245,154,194)] w-[5%] aspect-square"
                                    href="https://www.instagram.com/n1sa.85/"
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    <Instagram fill="#f59ac2" />
                                </a>
                                <a
                                    class="filter drop-shadow-[0_0_3.5px_rgb(245,154,194)] w-[5%] aspect-square"
                                    href="https://www.roblox.com/users/1281744385/profile"
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    <Roblox fill="#f59ac2" />
                                </a>

                                                            
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Nisa;
