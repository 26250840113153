import "../App.css";
import { useState } from "react";

import Discord from "../icons/Discord";
import Spotify from "../icons/Spotify";
import Roblox from "../icons/Roblox";
import Lastfm from "../icons/Lastfm";

import Visualizer from "../components/Visualizer";
import pfp from "../images/jc-pfp.png";
import background from "../images/jc-background.png"

function Jc() {
    const [title] = useState("jc");
    const [description] = useState("wherever the wind takes me");
    const [isEntered, setIsEntered] = useState(false);

    if (!isEntered) {
        return (
            <div class="">
                <div
                    className="fixed inset-0 flex items-center justify-center bg-[#445279] cursor-pointer"
                    onClick={() => setIsEntered(true)}
                >
                    <h1 class="text-[#ffffff] filter animate-pulse drop-shadow-[0_0_3.5px_rgb(255,255,255)] text-xl sm:text-xl md:text-xl lg:text-5xl">
                        &lt; click to enter &gt;
                    </h1>
                </div>
            </div>
        );
    }

    return (
        <div class="">
            <img
                class="fixed inset-0 w-full h-full object-cover brightness-[75%]"
                src={background}
            ></img>

            <div class="flex flex-col relative z-0 justify-center items-center h-screen">
                <div className="absolute inset-0 flex justify-center items-center">
                    <Visualizer user="jc" />
                </div>

                <div class="flex flex-col justify-center items-center h-screen w-[42rem] max-w-[90%] max-h-[95%]">
                    <div class="bg-[#3d4985]/15 min-w-full min-h-[26rem] mb-4 overflow-hidden shadow-sm shadow-[#000000]/50 backdrop-blur-md">
                        <div class="h-0.5 w-auto bg-[linear-gradient(100deg,_#6a80f8,_#7d93f9,_#90a7fa,_#a3bafb,_#b6ceff,_#a3bafb,_#90a7fa,_#7d93f9)] animate-gradient"></div>

                        <div class="w-full h-[14rem] flex justify-center items-center">
                            <img
                                src={pfp}
                                class="filter shadow-sm shadow-[#000000]/50 w-20 h-20 sm:w-24 sm:h-24 md:w-32 md:h-32 rounded-full"
                                alt="avatar"
                            ></img>
                        </div>

                        <div class="w-full h-[12rem] flex flex-col items-center text-white font-inter">
                            <h1 class="filter drop-shadow-[0_0_3.5px_rgb(255,255,255)] font-bold text-2xl sm:text-3xl md:text-4xl lg:text-5xl">
                                {title}
                            </h1>
                            <p class="text-text filter drop-shadow-[0_0_3.5px_rgb(255,255,255)] text-sm sm:text-md md:text-lg mt-2">
                                {description}
                            </p>

                            <div class="0 h-[5rem] w-full mt-auto flex flex-row justify-center items-center gap-5">
                                <a
                                    class="filter drop-shadow-[0_0_3.5px_rgb(255,255,255)] w-[5%] aspect-square"
                                    href="https://open.spotify.com/user/d054p1zi968or32x3yukv8725"
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    <Spotify fill="#e6e6e6" />
                                </a>
                                <a
                                    class="filter drop-shadow-[0_0_3.5px_rgb(255,255,255)] w-[5%] aspect-square"
                                    href="https://www.roblox.com/users/1724013114/profile"
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    <Roblox fill="#e6e6e6" />
                                </a>
                                <a
                                    class="filter drop-shadow-[0_0_3.5px_rgb(255,255,255)] w-[5%] aspect-square"
                                    href="https://www.last.fm/user/ton1ght_"
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    <Lastfm fill="#e6e6e6" />
                                </a>
                                <a
                                    class="filter drop-shadow-[0_0_3.5px_rgb(205,214,244)] w-[5%] aspect-square"
                                    href="discord://-/users/462535988070055936"
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    <Discord fill="#e6e6e6" />
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Jc;
