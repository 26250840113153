export default function Roblox({ fill }) {
    return (
        <svg
            class="h-auto w-auto"
            style = {{ fill: fill }}
            role="img"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path d="M18.926 23.998 0 18.892 5.075.002 24 5.108ZM15.348 10.09l-5.282-1.453-1.414 5.273 5.282 1.453z" />
        </svg>
    );
}
